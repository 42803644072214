import React, { useEffect, useState } from "react";
import Layout from "./layout";
import Image9 from "../../images/image-9.png";
import Image10 from "../../images/image-10.png";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BlankButton from "../ui/BlankButton";

const Dealers = ({ data }) => {
  return (
    <>
      <Layout>
        <div className="my-10 mx-auto max-w-screen-lg">
          <div className=" mx-6 flex flex-col space-y-2 md:space-y-4 md:mx-auto max-w-screen-md">
            <h5 className="uppercase font-semibold text-gray-500 text-base md:text-xl">
              {data.prismicBlogPage.data.type}
            </h5>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold text-gray-700 leading-normal md:leading-normal lg:leading-normal">
              {data.prismicBlogPage.data.blog_title.text}
            </h1>
            <h5 className="Capitalize font-regular text-gray-500 text-lg md:text-2xl">
              {data.prismicBlogPage.data.author_name}
            </h5>
            <h5 className="text-gray-400 text-sm md:text-lg md:font-medium font-light">
              {data.prismicBlogPage.data.blog_date}
            </h5>
          </div>
          <GatsbyImage
            image={getImage(data.prismicBlogPage.data.cover_image)}
            className="h-[640px] bg-cover my-4 mx-auto"
          />

          <div
            className=" mt-8 mx-6 prose lg:prose-lg xl:prose-xl md:mx-auto prose-blue-500"
            dangerouslySetInnerHTML={{
              __html: data.prismicBlogPage.data.blog_content.html,
            }}
          ></div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query blogs($slug: String) {
    prismicBlogPage(data: { slug: { eq: $slug } }) {
      data {
        author_name
        blog_date(formatString: "Do MMMM, YYYY")
        slug
        type
        cover_image
        {
          gatsbyImageData
        }
        blog_title {
          text
        }
        blog_content {
          html
        }
      }
    }
  }
`;

export default Dealers;
